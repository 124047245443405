import { Maybe, Nullable } from 'shared/util/types.util'
import { isResponseError, isSuperagentError } from 'shared/util/superagent.util'

export default class ApiError extends Error {
  parent: Error

  constructor(underlyingError: Error) {
    super(ApiError.extractMessage(underlyingError))

    this.parent = underlyingError
  }

  get status(): Maybe<number> {
    return isSuperagentError(this.parent) ? this.parent.status : undefined
  }

  private static extractMessage(error: Error): string | undefined {
    if (isResponseError(error)) {
      const response = error.response?.body
      if (response) {
        return ApiError.parseError(response.errors)
          ?? ApiError.parseError(response.error)
          ?? ApiError.parseError(response.message)
          ?? ApiError.parseError(response.messages)
          ?? ApiError.parseError(response)
      }
    }

    return error.message
  }

  private static parseError(error?: Nullable<unknown>): string | undefined {
    if (error == null) {
      return undefined
    }

    if (Array.isArray(error)) {
      return error.map((item) => this.itemToString(item)).join(', ')
    }

    return this.itemToString(error)
  }

  private static itemToString(item: unknown): string {
    return typeof item === 'string' ? item : JSON.stringify(item)
  }
}
