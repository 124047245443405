import superagent from 'superagent'

export type SuperagentError = superagent.HTTPError | superagent.ResponseError

export function isSuperagentError(error: Error): error is SuperagentError {
  return isHttpError(error) || isResponseError(error)
}

export function isHttpError(error: Error): error is superagent.HTTPError {
  return 'status' in error && 'path' in error && 'method' in error
}

export function isResponseError(error: Error): error is superagent.ResponseError {
  return 'status' in error && 'response' in error
}
