import { AnyObject, TableColumnProps } from 'components_shared/table/DataTable'
import { HeaderProps, Renderer } from 'react-table'
import { compact } from 'lodash'

type RawColumnProps<T extends AnyObject> = TableColumnProps<T> & {
  Header?: string | Renderer<HeaderProps<T>>
  headerClassName?: string
}

export function createColumn<T extends AnyObject>(
  { Header, width, maxWidth, minWidth, ...rest }: RawColumnProps<T>,
): TableColumnProps<T> {
  const widthNumber = typeof width === 'number' ? width : undefined
  minWidth ??= Math.min(...compact([100, widthNumber, maxWidth]))
  maxWidth ??= Math.max(...compact([400, widthNumber, minWidth]))
  return {
    Header: Header ?? rest.name,
    minWidth,
    width: width ?? Math.max(Math.min(180, maxWidth), minWidth),
    maxWidth,
    hasToggle: true,
    ...rest,
  }
}

export function removePageParam(queryParams: URLSearchParams): void {
  queryParams.delete('page')
}
